import React, { Fragment } from 'react';
import './HomePage.css';

const HomePage = () => {
    return (
        <>
                <div className={'wrapper'}>
					<div className='section'>
						<div className='photo'></div>
						<div className='text'>
							<div>
								<div className='name'>Cristian</div>
								<div className='title'>Software Engineer</div>
							</div>
							<div className='description'>
								Software engineer with over 11 years of experience crafting
								exceptional user experiences and architecting scalable systems.
							</div>
						</div>
					</div>
					<div className='section'>
						<div className='text'>
							<div className='description'>
								code/work/career/exp
							</div>
							<div className='title'>
								<div>
									<a target="_blank" rel="noopener noreferrer" href="https://github.com/RusuTraianCristian">GitHub</a>
								</div>
                                <div>
									<a target="_blank" rel="noopener noreferrer" href="https://s3.us-west-2.amazonaws.com/rusutraiancristian.com/SER.pdf">resume</a>
                                </div>
							</div>
						</div>
					</div>
					<div className='section'>
						<div className='text'>
							<div className='description'>
								entrepreneurship
							</div>
							<div className='title'>
								<div>
									<a target="_blank" rel="noopener noreferrer" href="https://telluric.ai">founder Telluric AI</a>
								</div>
                                <div>
									<a target="_blank" rel="noopener noreferrer" href="https://dispute.live">founder Dispute Live</a>
                                </div>
							</div>
						</div>
					</div>
					<div className='section'>
						<div className='text'>
							<div className='description'>
								essays
							</div>
							<div className='title'>
								<div>
									<a href="/understanding-market-dynamics">Understanding Market Dynamics</a>
								</div>
							</div>
						</div>
					</div>
					<div className='section'>
						<div className='text'>
							<div className='description'>
								social
							</div>
							<div className='title'>
								<div>
									<a target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/rusutraiancristian/">LinkedIn</a>
								</div>
                                <div>
									<a target="_blank" rel="noopener noreferrer" href="https://twitter.com/RusuTCristian">X</a>
                                </div>
							</div>
						</div>
					</div>
					<div className='section'>
						<div className='text'>
							<div className='description'>
								email
							</div>
							<div className='title'>
                                <div>
									<a target="_blank" rel="noopener noreferrer" href="mailto:rusutraiancristian@gmail.com">rusutraiancristian@gmail.com</a>
                                </div>
							</div>
						</div>
					</div>
                </div>
        </>
    );
}

export default HomePage;